import React, { Component } from 'react';
import './sectionheader.css';

class sectionheader extends Component {
    render() {
        return (
            <div className='SectionHeader-Container'>
                {/* <p className='SectionHeader-Container-Description'>{this.props.description}</p> */}
                <div className='Divider Divider-1'></div>
                <h5 className='SectionHeader-Container-Name'>{this.props.title}</h5>
                <div className='Divider Divider-2'></div>
            </div>
        );
    }
}

export default sectionheader;