import React, { Component } from 'react';
import './footer.css'
import Placeholder from '../../images/logo.gif'

class footer extends Component {
    render() {
        return (
            <div className='Footer'>
                {/* <img className='Footer-image' src={Placeholder}/> */}
                {/* <h3>Let's talk</h3>
                <p>I'm now currently considering positions:
                    <ul>
                        <li>Remote</li>
                    </ul>
                </p> */}
            </div>
        );
    }
}

export default footer;