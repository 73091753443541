import React, { Component } from 'react';
import './work.css'
import SectionHeader from '../sectionheader/sectionheader.js'
import CaseStudyItem from '../casestudyitem/casestudyitem.js'
import Learn from '../../images/header.png'
import MedTabHeader from '../../images/MedTabHeader.png'
import EngagementHeader from '../../images/EngagementHeader.png'

class work extends Component {
    render() {
        return (
            <div className='Work-Container'>
                <SectionHeader title="work" description="I’ve been working as a Product Designer in healthtech helping people change their habits."/>
                <div className='Work-Container-CaseStudies'>

                    <CaseStudyItem
                    subtitle="Product Design / Research / Illustrations"
                    title="Weight-loss medication management"
                    description={<>I was the <span className='skill skill-Design'>Product Designer</span> responsible for <span className='skill skill-Research'>testing</span> and <span className='skill skill-Prototype'>prototyping</span> a new app tab to help users manage their medication delivery. </>}
                    hero={MedTabHeader}
                    />

                    <CaseStudyItem
                    subtitle="Product Design / Research / Illustrations / Animations"
                    title="Improving early engagement and revenue in B2B"
                    description={<>I worked as a <span className='skill skill-Design'>Product Designer</span> in a B2B product at Second Nature. I <span className='skill skill-Research'>analysed data and researched</span> with users to uncover blockers in early engagement. I <span className='skill skill-Prototype'>prototyped</span> new iterations that led to an increase in activation and revenue.</>}
                    hero={EngagementHeader}
                    />

                    <CaseStudyItem
                    subtitle="Product Design / Research / Illustrations / Animations"
                    title="Reducing churn before starting a programme by 40%"
                    description={<>I worked as a <span className='skill skill-Design'>Product Designer</span> in the growth team at Second Nature. I was responsible for <span className='skill skill-Research'>researching</span>, <span className='skill skill-Prototype'>prototyping</span>, and collaborating with PMs and Engineers to reduce churn before the start of the Second Nature programme.</>}
                    hero={Learn}
                    />
                </div>
            </div>
        );
    }
}

export default work;