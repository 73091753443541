import React, { Component } from 'react';
import './studyhero.css'
// import Header from '../../images/header.png'

class studyhero extends Component {
    render() {
        return (
            <a href={this.props.url} className='StudyHero-Container'>
                <img className='StudyHero-Image' src={this.props.hero}/>
            </a>
        );
    }
}

export default studyhero;