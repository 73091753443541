import React, { Component } from 'react';
import './fixedtopbar.css'
import Logo from '../../images/logo.gif'
import Time from '../time/time.js'

class fixedtopbar extends Component {
    render() {
        return (
            <div className='FixedTopBar-Container'>
                    <a href="/" className='HomeButton'><img className="Logo" src={Logo} /></a>
                    {/* <Time/> */}
            </div>
        );
    }
}

export default fixedtopbar;