import React, { Component } from 'react';
import './announcement.css'

class announcement extends Component {
    render() {
        return (
            <div className='Announcement'>
               <div className='Heading'>
               {/* <div className='Circle'>
               <div className='CircleOut'>
                    <div className='CircleIn'></div>
                    </div>
                </div> */}
                <h3>Feel free to reach out</h3>
                </div>
                <p>
                Email me at gvns0x@gmail.com or <a target="_blank" className='ContactLink' href="https://www.linkedin.com/in/gvns/">message me on LinkedIn</a>
                </p>
            </div>
        );
    }
}

export default announcement;