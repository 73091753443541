import React, { Component } from 'react';
import './hero.css'
import HeroImage from '../../images/hero.png'
import Announcement from '../announcement/announcement.js'

class Hero extends Component {
    render() {
        return (
            <div className='Hero-Container-Value'>
            <div className='Hero-Container'>
                
                <img className="HeroImage" src={HeroImage}/>
                <div className='Hero-Container-Content'>
                    <p>My name is João Araújo. I am a Product Design Lead launching new products and helping people adopt healthy behaviours at Second Nature.
                        
                        <br/><br/>In the past I’ve learned from dropping out of uni courses, meeting people from all over the world, moving to a new country, being vulnerable and actively listening. Cheering for the underdog. Loving what makes people irrationally mad. It’s always OK to find.
                        </p>
                </div>
            </div>
            </div>
        );
    }
}

export default Hero;