import React, { Component } from 'react';
import './casestudyitem.css'
import Button from '../button/button.js'
import Images from '../study/studyimage/studyimage.js'
import StudyHero from '../studyhero/studyhero';

class casestudyitem extends Component {
    render() {
        return (
            <div className='CaseStudyItem-Container'>
                <StudyHero hero={this.props.hero} url={this.props.url}/>
                <div className='CaseStudyItem-Container-Content'>
                    <div className='CaseStudyItem-Container-Left'>
                        <a href={this.props.url} className='CaseStudyItem-Container-Titles'>
                            <h4 className='CaseStudyItem-Subtitle'>{this.props.subtitle}</h4>
                            <h2 className='CaseStudyItem-Title'>{this.props.title}</h2>
                        </a>
                        {/* <Button cta={this.props.cta}/> */}
                    </div>
                    <p className='CaseStudyItem-Description'>{this.props.description}</p>
                </div>
            </div>
        );
    }
}

export default casestudyitem;