import React from 'react'
import logo from './logo.svg';
import './App.css';
import FixedTopBar from './components/fixedtopbar/fixedtopbar.js'
import Hero from './components/hero/hero.js'
import Section from './components/section/section.js'
import CaseStudyItem from './components/casestudyitem/casestudyitem.js'
import SideProject from './components/sideproject/sideproject.js'
import Work from './components/work/work.js'
import Footer from './components/footer/footer.js'
import Gradient from './components/article/gradient/gradient';
import Announcement from './components/announcement/announcement.js'

function App() {

  return (
    <div className="App">
      <div className='App-Container'>
        <div className='App-Container-Top'>
          <FixedTopBar/>
          <Hero/>
        </div>
        <Work/>
        {/* <Section title="other" description="I’ve been working as a Product Designer in healthtech helping people change their habits." content={<SideProject/>}/> */}
        <Announcement/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;